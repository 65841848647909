/*
# General colors
*/
$space-blue: unquote('#1976d2');
$space-blue--hover: unquote('#2a88e6');
$space-blue--hover-darker: unquote('#1a62a8');

$space-secondary-background: unquote('#E3EBF6');
$space-secondary-main: unquote('#fafafa');


$space-third-main: unquote('#ecf0f1');
$space-third-main--hover: unquote('#e3e7e8');

/*
# Form Colors
*/
$space-tip--ok: unquote('#0ac100');
$space-tip--bad: unquote('#e00000');
$space-input--invalid: unquote('#DC143C');

/*
# Utility colors - icons etc
*/

$space-superlightgray: unquote('#d2d2d2');
$space-lightgray: unquote('#aaaaaa');
$space-mediumgray: unquote('#999999');
$space-darkgray: unquote('#484848');

$space-lightblue: unquote('#00aaf6');

/*
# Shadows and gradients
*/
$shadow-light: 0px 0px 0px 1px unquote('#d9e1ec');
$shadow-basic: 0px 3px 3px 0px unquote('#3c3c3c33');
$shadow-medium: 0px 6px 4px 0px unquote('#3c3c3c4a');

$shadow-text: 1px 1px 0px unquote('#0000005c');

$gradient-main: linear-gradient(180deg,#1976d2 0,#1976d2 7%,#18b7fe 100%);

/*
# new layout colors
*/
$blue: rgb(1 126 178);
$blue2: rgb(14 170 235);
$blue3: rgb(38 179 237);
$blue4: rgb(245 248 252);
$blueHover: rgb(26 139 186);
$blueHover2: #1A8BBA;
$blueActive: rgb(52 152 193);
$blueActive2: #3498C1;

$naivyBlue: rgb(7 49 74);

$gray: rgb(112 119 122);
$gray1: rgb(112 119 122);
$gray2: rgb(180 184 185);
$gray3: rgb(230 231 232);
$gray4: rgb(255 253 254);
$gray5: rgb(238 238 239);
$gray6: rgb(234 234 234);
$gray7: rgb(79 88 92);
$gray8: rgb(250 250 250);
$gray9: rgb(249 249 249);
$grayActive: rgb(198 201 202);
$grayHover: rgb(155 160 162);
$grayTransparent: rgb(246 246 244 / 0.8);
$lightGray: #C6C9CA;
$darkGray: #F4F9FF;
$leafletMapColor: #E3E3E3;

$primaryLoadingState: #80BFD9;

$flowkitPurple: #7B61FF;
$white:  rgb(255 255 255);
$black: rgb(1 17 23);
$blackActive: rgb(103 112 116);
$blackHover: rgb(52 65 69);
$black-1-0: rgb(1 17 23 / 0);
$black-1-50: rgb(1 17 23 / 0.5);
$black-1-80: rgb(1 17 23 / 0.8);
$white-40: rgb(255 255 255 / 0.4);
$white-70: rgb(255 255 255 / 0.7);

$red: rgb(251 199 199); // --red-2
$red1: rgb(195 9 9);
$red2: #F40B0B;
$redHover: rgb(220 10 10);
$redActive: rgb(195 9 9);
$redDisabled: rgb(253 206 206); // --red3
$redHover3: rgb(254 231 231);
$redActive3: rgb(255 239 239);


$green: rgb(13 83 2 / 0.7);


$yellow: rgb(255 199 0);
/*
# Fonts
*/
$main-font: -apple-system, 'Helvetica', sans-serif;
$secondary-font: 'Metropolis-Light', sans-serif;
$tertiary-font: 'Metropolis-Regular', sans-serif;

/*
# Media queries (max-width) breakpoints
*/

$extraSmall: 576px;
$small: 768px;
$medium: 992px;
$large: 1200px;

/*
# z-index priorities
*/

$toastZindex: 2010;
$modalZindex: 2000;
$leftSideBarZindex: 1001;
$onMapNavItems: 400;
$aboveMapSelectionArea: 998;
$aboveLeafLetMark: 1001;


/*
# container sizing
*/

$addCommentHeight: 450px;
$rightPanelDesktopSize: 394px;
